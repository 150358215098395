define("shop/pods/components/website/website-page/component", ["exports", "@ember/component", "ember-concurrency", "shop/mixins/images", "jquery", "ramda", "tiny-slider/src/tiny-slider", "@ember/runloop", "ember-concurrency-decorators", "@ember/object", "@ember/service", "shop/utils/nventor"], function (_exports, _component, _emberConcurrency, _images, _jquery, R, _tinySlider, _runloop, _emberConcurrencyDecorators, _object, _service, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_images.default, (_obj = {
    configService: (0, _service.inject)('config'),
    websiteService: (0, _service.inject)('website'),
    storage: (0, _service.inject)(),
    date: (0, _service.inject)(),
    flowsService: (0, _service.inject)('flows'),
    isAlreadySetup: false,
    MEDIA_DESKTOP: 1000,
    MEDIA_TABLET: 700,
    baseUrl: 'https://ucarecdn.com',

    didInsertElement() {
      this._super(...arguments);

      if (this.isAlreadySetup) {
        this.websiteService.scrollToSavedScrollPosition('already-website-page');
      } else {
        this.set('isAlreadySetup', true);
        this.setupPage.perform();
      }
    },

    didRender() {
      this._super(...arguments);

      this.replaceDomElementsTask.perform();
    },

    *replaceDomElementsTask() {
      yield (0, _emberConcurrency.timeout)(100); // if (this.bigordrProductsSliderArray) {
      //   this.replaceProductsSliders()
      // }
      // @TODO: this should be moved out

      if (this.bigordrScratchCardsArray) {
        this.replaceScratchCards();
      }

      if (this.bigordrFormsArray) {
        this.replaceBigOrdrForm();
      }
    },

    isPagePreview: (0, _object.computed)('website.isPreview', function () {
      const website = this.website;
      const isPreview = website.isPreview || false;
      return isPreview;
    }),
    hasPageSchedules: (0, _object.computed)('page.hasPageSchedules', function () {
      const page = this.page;

      if (!_nventor.default.isNilOrEmpty(page)) {
        return false;
      }

      const hasPageSchedules = page.hasPageSchedules || false;
      return hasPageSchedules;
    }),
    pageSchedules: (0, _object.computed)('page.{hasPageSchedules,pageSchedules}', function () {
      const page = this.page;

      if (!_nventor.default.isNilOrEmpty(page)) {
        return false;
      }

      const hasPageSchedules = page.hasPageSchedules || false;
      const pageSchedules = page.pageSchedules || [];

      if (!hasPageSchedules) {
        return [];
      }

      return R.map(schedule => {
        schedule.status = `page_${schedule.status}`;
        return schedule;
      })(pageSchedules);
    }),

    isAllowToLoadPage() {
      var _this$website, _this$website2, _this$page, _this$page2;

      const hasPassword = (this === null || this === void 0 ? void 0 : (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.hasPassword) || false;
      const isPreview = (this === null || this === void 0 ? void 0 : (_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.isPreview) || false;
      const page = this.page || {};
      const pageKey = (this === null || this === void 0 ? void 0 : (_this$page = this.page) === null || _this$page === void 0 ? void 0 : _this$page._key) || '';
      const status = (this === null || this === void 0 ? void 0 : (_this$page2 = this.page) === null || _this$page2 === void 0 ? void 0 : _this$page2.status) || '';
      const isPagePasswordCorrect = this.storage.getValue(`isPagePasswordCorrect-${pageKey}`, this.website);

      if (hasPassword && !isPagePasswordCorrect) {
        return false;
      } // shop page will return undefined page


      if (_nventor.default.isNilOrEmpty(page)) {
        return true;
      }

      if (hasPassword && isPagePasswordCorrect) {
        return true;
      }

      if (isPreview && !hasPassword) {
        return true;
      }

      if (!isPreview && status === 'active') {
        return true;
      }

      return false;
    },

    removePlaceHolderImages(css = '') {
      const removeImagePlaceHolderRegex = /background-image:\s*url\(\s*(?:'|")?data:image\/svg\+xml;base64,[A-Za-z0-9+=]+(?:'|")?\s*\);/gm;
      return R.replace(removeImagePlaceHolderRegex, '')(css);
    },

    setupPage: (0, _emberConcurrency.task)(function* () {
      var _virtualDiv, _virtualDiv2;

      let templateHtml = this.templateHtml || '';
      let podKey;
      let pageKey;
      let version;
      let cssRaw = this.loadCssRaw;
      let isPreview = false;
      const website = this.website;
      const isAllowToLoadPage = this.isAllowToLoadPage();

      if (!templateHtml) {
        const page = this.page; // if (!page.hasTemplate) {
        // @TODO: what happens when no template?
        // }

        this.websiteService.setupTitle(website, page);
        podKey = website.get('_data.podKey');
        pageKey = this.get('page._key');
        version = this.get('page.version');
        isPreview = website.isPreview || false;

        if (pageKey && version) {
          const cssUrl = this.websiteService.getUrl({
            podKey,
            pageKey,
            version,
            extension: 'css'
          });
          cssRaw = yield this.websiteService.request(cssUrl, {
            method: 'GET',
            dataType: 'text'
          });
        }

        if (!R.is(String, cssRaw)) {
          cssRaw = '';
        }

        if (isAllowToLoadPage || !website.hasPassword) {
          if (pageKey && version) {
            templateHtml = yield this.websiteService.get('loadHtmlTask').perform({
              podKey,
              pageKey,
              version
            });
          }
        }
      }

      cssRaw = this.removePlaceHolderImages(cssRaw);

      if (!templateHtml) {
        return;
      } // Parse the HTML string into a Document object


      const pageContent = (0, _jquery.default)('#page-custom-content');
      const defaultWidth = pageContent.outerWidth(); // Remove or hide dom elements before render

      let virtualDiv = document.createElement('div');
      virtualDiv.setAttribute('data-type', 'virtual-div');
      templateHtml = R.pipe(R.replace(/src="https:\/\/ucarecdn\.com/gm, 'safeSrc="https://ucarecdn.com'), // remove src single quote and double quotes
      R.replace(/src='https:\/\/ucarecdn\.com/gm, 'safeSrc="https://ucarecdn.com'), R.replace(/srcset="https:\/\/ucarecdn\.com/gm, 'safeSrc="https://ucarecdn.com'), // remove srcset single quote and double quotes
      R.replace(/srcset='https:\/\/ucarecdn\.com/gm, 'safeSrc="https://ucarecdn.com'))(templateHtml);
      virtualDiv.innerHTML = templateHtml || ''; // const parser = new DOMParser();
      // let virtualDocument = parser.parseFromString(`<div id='virtual-div'>${templateHtml}</div>`, 'text/html');
      // let virtualDiv = virtualDocument.getElementById('virtual-div')

      const embeddedScript = virtualDiv.querySelector('script');

      if ((_virtualDiv = virtualDiv) !== null && _virtualDiv !== void 0 && _virtualDiv.innerHTML) {
        virtualDiv = this.modifyPageContent(virtualDiv, defaultWidth);
      }

      pageContent.html(((_virtualDiv2 = virtualDiv) === null || _virtualDiv2 === void 0 ? void 0 : _virtualDiv2.innerHTML) || '');
      this.updateHrefs(); // set bigordr tabs with header anchors

      this.setTabsWithHeaderAnchor();
      const cssStyle = R.pipe(R.replace(/}/gm, '}\n'), R.split('\n'), R.reject(R.includes(':root')), R.join(''))(cssRaw);
      const optimizedCss = this.optimizeBackgroundImages(defaultWidth, cssStyle);
      this.set('cssStyle', optimizedCss);
      this.applyBodyStyleToDivWrapper({
        templateHtml,
        optimizedCss
      });
      this.wrapUnWrappedCells();
      this.set('isPagePasswordCorrect', isAllowToLoadPage);

      if (isAllowToLoadPage || !website.hasPassword) {
        var _this$shop;

        const shopUrl = this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.url;

        if (shopUrl) {
          let pageDetails = this.loadPageDetails;

          if (!pageDetails) {
            pageDetails = yield this.websiteService.getPageTask.perform({
              pageKey,
              data: {
                shopUrl,
                isPreview
              }
            });
          } // set bigordr products slider


          this.setBigordrProductsSlider(pageDetails);
        }

        if (embeddedScript) {
          pageContent.append(embeddedScript);
        } // set bigordr blogs


        this.setBigordrBlogsSlider();
      }

      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        this.websiteService.scrollToSavedScrollPosition('website-page');
      });
    }),
    checkPagePasswordTask: (0, _emberConcurrency.task)(function* () {
      var _this$page3;

      const website = this.website;
      const pageKey = ((_this$page3 = this.page) === null || _this$page3 === void 0 ? void 0 : _this$page3._key) || '';
      const password = this.password || '';

      if (password && pageKey) {
        const isPagePasswordCorrect = yield this.websiteService.checkPagePasswordTask.perform({
          pageKey,
          password
        });

        if (isPagePasswordCorrect) {
          this.set('pagePasswordError', '');
          this.storage.setValue(`isPagePasswordCorrect-${pageKey}`, true, website, 86400000);
          this.setupPage.perform();
        } else {
          this.storage.setValue(`isPagePasswordCorrect-${pageKey}`, false, website, 86400000);
          this.set('pagePasswordError', 'passwords error');
        }
      }

      if (!password) {
        this.storage.setValue(`isPagePasswordCorrect-${pageKey}`, false, website, 86400000);
        this.set('pagePasswordError', 'password (at least 8 characters)');
      }
    }),

    setTabsWithHeaderAnchor() {
      const allTabsWithHeaderAnchor = Array.from(document.querySelectorAll('[data-bigordr-tabs-with-header-anchor]'));
      const allTabs = Array.from(document.querySelectorAll('.bigordr-tab-container__sticky-tabs'));
      let hasTabsWithHeaderAnchor = false;
      const anchorLinks = R.pipe(R.map(tab => ({
        label: R.propOr('', 'textContent')(tab),
        href: `#${R.propOr('', 'id')(tab)}`
      })))(allTabsWithHeaderAnchor);
      this.set('anchorLinks', anchorLinks);

      if (!R.isEmpty(anchorLinks)) {
        var _this$websiteService;

        // Set updated height on all tabs
        let tickyTabsClassName = 'bigordr-tab-container__sticky-tabs-under-links-slider';

        if (this !== null && this !== void 0 && (_this$websiteService = this.websiteService) !== null && _this$websiteService !== void 0 && _this$websiteService.isShopIndexRoute) {
          var _this$websiteService2, _this$websiteService3, _this$websiteService4;

          if (this !== null && this !== void 0 && (_this$websiteService2 = this.websiteService) !== null && _this$websiteService2 !== void 0 && (_this$websiteService3 = _this$websiteService2.website) !== null && _this$websiteService3 !== void 0 && (_this$websiteService4 = _this$websiteService3.shop) !== null && _this$websiteService4 !== void 0 && _this$websiteService4.hasFaqs) {
            tickyTabsClassName = 'bigordr-tab-container__sticky-tabs-under-links-slider-shop-index';
          } else {
            tickyTabsClassName = 'bigordr-tab-container__sticky-tabs-under-links-slider-shop-index-no-faqs';
          }
        }

        R.forEach(tab => {
          tab.classList.add(tickyTabsClassName);

          if (this.websiteService.isMobile) {
            tab.classList.add('bigordr-tab-container__sticky-tabs__is-mobile');
          }
        })(allTabs);
        hasTabsWithHeaderAnchor = true;
      }

      if (!R.isEmpty(allTabs)) {
        R.forEach(tabGroup => {
          const domNavBar = document.querySelector('.my-navbar-header');
          if (!domNavBar) return;
          const headerNavBarClientRect = domNavBar.getBoundingClientRect();
          tabGroup.style.top = `${Math.floor(headerNavBarClientRect.bottom)}px`;
        })(allTabs); // on selectTab scroll top

        R.pipe(R.pluck('children'), R.flatten, R.map(tabHeader => {
          tabHeader.addEventListener('click', event => {
            var _event$target, _event$target$closest;

            const bodyRect = document.body.getBoundingClientRect();
            const tabRect = event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : (_event$target$closest = _event$target.closest('[sticky-top]')) === null || _event$target$closest === void 0 ? void 0 : _event$target$closest.getBoundingClientRect();

            if (_nventor.default.isNotNilOrEmpty(tabRect)) {
              const tabRectTop = hasTabsWithHeaderAnchor ? tabRect.top - 90 : tabRect.top;
              const offset = tabRectTop - bodyRect.top;
              scroll({
                top: offset,
                behavior: 'smooth'
              });
            }
          });
        }))(allTabs);
      }
    },

    /**
     * Mitigates the scenario when cells arent wrapped on a container that limit the width
     */
    wrapUnWrappedCells() {
      const unWrappedCellsArray = document.querySelectorAll('#page-custom-content > .bigordr-inner-row');
      R.pipe( // R.reject(cell => cell.nextSibling !== null),
      R.reject(unCell => unCell.querySelector('.bigordr-cell-wrapper')), R.forEach( // element that will be wrapped
      unWrappedCell => {
        // create wrapper container
        const divWrapper = document.createElement('div');
        divWrapper.classList.add('bigordr-cell-wrapper');
        divWrapper.innerHTML = unWrappedCell.innerHTML;
        unWrappedCell.innerHTML = '';
        unWrappedCell.appendChild(divWrapper);
      }))(unWrappedCellsArray);
    },

    setBigordrBlogsSlider() {
      const blogsSlidersDom = Array.from(document.querySelector('#page-custom-content').querySelectorAll('.bigordr-blogs-wrapper'));
      const blogsSlidersSettings = R.pipe(R.map(blogsSliderWrapper => {
        blogsSliderWrapper.style.visibility = 'hidden';
        return blogsSliderWrapper;
      }), R.map(wrapper => wrapper.dataset), R.map(R.pick(['bigordrBlogsSliderType', 'bigordrBlogsSliderLimitBy', 'bigordrBlogsTags'])), R.map(dataSet => ({
        style: dataSet.bigordrBlogsSliderType,
        limitBy: dataSet.bigordrBlogsSliderLimitBy,
        tags: dataSet.bigordrBlogsTags
      })))(blogsSlidersDom);
      const blogsSlidersKeys = R.pipe(R.map(blogSliderDom => blogSliderDom.querySelectorAll('img')), R.map(R.map(img => {
        if (img) {
          return img.getAttribute('data-bigordr-blog-key');
        }
      })), R.reject(R.anyPass([R.isNil, R.isEmpty])))(blogsSlidersDom);
      const blogs = R.pipe(R.mapObjIndexed((blogModels, index) => {
        blogModels = R.sortBy(R.pipe(R.path(['_data', '_key']), R.indexOf(R.__, blogsSlidersKeys[index])))(blogModels);
        blogModels.style = blogsSlidersSettings[index].style;
        blogModels.limitBy = blogsSlidersSettings[index].limitBy;
        blogModels.tags = blogsSlidersSettings[index].tags;
        blogModels.keys = blogsSlidersKeys[index];
        blogsSlidersDom[index].setAttribute('data-index', parseInt(index));
        return blogModels;
      }), R.values)(blogsSlidersKeys);
      this.set('bigordrBlogsSliderArray', blogs);
    },

    setBigordrStoriesSlider(storiresSlidersContainers) {
      if (!storiresSlidersContainers || R.isEmpty(storiresSlidersContainers)) {
        return [];
      }

      const containersArray = Array.from(storiresSlidersContainers);
      const result = R.map(container => {
        const contentLists = Array.from(container.querySelectorAll('.gjs-lory-slide'));
        const imageObjects = [];
        container.style.display = 'none';
        R.forEach(content => {
          const imageObject = _object.default.create({
            modalContent: (content === null || content === void 0 ? void 0 : content.innerHTML) || ''
          });

          imageObjects.push(imageObject);
        }, contentLists);
        return {
          contentLists,
          imageObjects
        };
      }, containersArray);
      this.set('bigordrStoriesSliderArray', result);
    },

    /**
     * Modify page content before render
     * @param {HTMLDivElement} virtualDom
     * @param {string} defaultWidth
     */
    modifyPageContent(virtualDom, defaultWidth) {
      // Hide all products before render
      const domProducts = virtualDom.querySelectorAll('.bigordr-product');
      R.forEach(domProduct => {
        domProduct.style.display = 'none';
      })(domProducts);
      const bigordrBlogsWrapper = virtualDom.querySelectorAll('.bigordr-blogs-wrapper');
      R.forEach(bigordrBlogWrapper => {
        bigordrBlogWrapper.style.visibility = 'hidden';
      })(bigordrBlogsWrapper);
      const allTabs = Array.from(virtualDom.querySelectorAll('.bigordr-tab-container__sticky-tabs'));
      const domNavBar = document.querySelector('.my-navbar-header');

      if (!domNavBar) {
        return false;
      }

      const navbarHeaderClientRect = domNavBar.getBoundingClientRect();
      let adjustmentHeight = navbarHeaderClientRect.bottom;
      const timer = document.querySelector('.timer-container');

      if (timer) {
        const timerClientRect = timer.getBoundingClientRect();
        adjustmentHeight += Math.floor(timerClientRect.height);
      }

      const faqsContainer = document.querySelector('.tabs-on-top-container');

      if (faqsContainer) {
        const faqsContainerClientRect = faqsContainer.getBoundingClientRect();
        adjustmentHeight += Math.floor(faqsContainerClientRect.height);
      }

      const linksSlider = document.querySelector('.shop-tags-menu__mobile-container');

      if (linksSlider) {
        const linksSliderClientRect = linksSlider.getBoundingClientRect();
        adjustmentHeight += Math.floor(linksSliderClientRect.height);
      }

      R.forEach(allTab => {
        const extraMarginFromPreviousElement = 5;
        adjustmentHeight += extraMarginFromPreviousElement;
        allTab.style.top = `${adjustmentHeight}px`;
      })(allTabs);
      const images = (0, _jquery.default)(virtualDom).find('img');
      const pictureSources = Array.from(virtualDom.querySelectorAll('source'));
      this.optimizeImages(defaultWidth, images);
      this.optimizePictureSource(defaultWidth, pictureSources);
      this.wrapElementsWithLinks(virtualDom); // set bigordr slider

      this.setBigordrSlider({
        virtualDom
      });
      const sliderNativeContainers = virtualDom.querySelectorAll('.lory-frame ul'); // setup scroll snap

      R.forEach(slider => {
        var _slider$classList;

        if (!(slider !== null && slider !== void 0 && (_slider$classList = slider.classList) !== null && _slider$classList !== void 0 && _slider$classList.add)) {
          return;
        }

        slider.classList.add('scroll-snap-type__x_mandatory');
        R.pipe(R.pathOr([], ['childNodes']), R.forEach(child => {
          var _child$classList;

          if (!(child !== null && child !== void 0 && (_child$classList = child.classList) !== null && _child$classList !== void 0 && _child$classList.add)) {
            return;
          }

          child.classList.add('scroll-snap-align__start');
        }))(slider);
      })(sliderNativeContainers);
      this.applyBigordrNativeSliderSchedule(sliderNativeContainers);
      const storiresSlidersContainers = virtualDom.querySelectorAll('[data-gjs-component="bigordr-stories"]');
      this.setBigordrStoriesSlider(storiresSlidersContainers);
      const scratchCardContainers = virtualDom.querySelectorAll('[data-gjs-component="bigordr-scratch-card"]'); // console.log('scratchCardContainers :',scratchCardContainers)

      this.setBigordrScratchCards({
        virtualDom,
        scratchCardContainers
      });
      const bigordrFormsContainers = virtualDom.querySelectorAll('.bigordr-form');
      this.setBigordrForms({
        virtualDom,
        bigordrFormsContainers
      }); // // set bigordr videoes

      this.setBigordrVideoes({
        virtualDom
      });
      return virtualDom;
    },

    setBigordrVideoes({
      virtualDom
    } = {}) {
      const videoContainers = virtualDom.querySelectorAll('.bigordr-video-outer-wrapper');
      if (!(videoContainers !== null && videoContainers !== void 0 && videoContainers.length) || videoContainers.length === 0) return; // loads the IFrame Player API code asynchronously

      const bigordrYoutubeId = 'bigordr_youtube_script';
      const scriptTagId = 'youtube_iframe_api';
      const youtubeIframeApi = 'https://www.youtube.com/iframe_api';
      const scriptTagElement = document.getElementById(scriptTagId);

      if (!scriptTagElement) {
        const scriptTag = document.createElement('script');
        scriptTag.defer = true;
        scriptTag.id = scriptTagId;
        scriptTag.src = youtubeIframeApi;
        document.head.appendChild(scriptTag);
      }

      const playerObjectsList = []; // set youtube videoes

      videoContainers.forEach(container => {
        const iframe = container === null || container === void 0 ? void 0 : container.querySelector('iframe');
        if (!iframe) return;
        const src = (iframe === null || iframe === void 0 ? void 0 : iframe.src) || '';
        const hasYoutubeEmbedUrl = R.includes('www.youtube.com/embed')(src);
        const hasYoutubeNoCookieEmbedUrl = R.includes('www.youtube-nocookie.com/embed')(src);
        const isYoutube = hasYoutubeEmbedUrl || hasYoutubeNoCookieEmbedUrl;
        if (!isYoutube) return;
        const playerId = `bigordr_${iframe.id}`;
        const splitUrl = R.split('/embed/')(src)[1];
        const [videoId, queryParams] = R.split('?')(splitUrl);
        const isAutoplay = R.includes('autoplay=1')(queryParams);
        const playerVars = {
          origin: window.location.host,
          autoplay: isAutoplay
        };

        if (queryParams) {
          iframe.src = src + '&enablejsapi=1';
        } else {
          iframe.src = src + '?enablejsapi=1';
        } // @TEMP-FIX: disable this feature until we can get autoplay to work on every platform
        // const noControls = R.includes('controls=0')(queryParams)
        // if (noControls) {
        //   container.style.pointerEvents = 'none'
        // }


        const playerObject = {};
        playerObject.playerVars = { ...playerVars
        };
        playerObject.playerId = playerId;
        playerObject.videoId = videoId;
        playerObjectsList.push(playerObject);
      }); // creates an <iframe> (and YouTube player) after the API code downloads if target is <div>
      // if uses <iframe> with 'enablejsapi=1' in src, it will be added to th iframe

      let scriptHtmlOnYouTubeIframeAPIReady = `
      function onYouTubeIframeAPIReady () {
    `; // after the API code downloads

      let scriptHtmlOnPlayerReady = `
      function onPlayerReady (event) {
        const autoplayList = []
    `; // Loop through the playerObjects array
      // add the code to scriptHtmlOnYouTubeIframeAPIReady

      playerObjectsList.forEach(playerObj => {
        const playerId = playerObj.playerId;
        const playerVars = playerObj.playerVars;
        scriptHtmlOnYouTubeIframeAPIReady += `
          let player_${playerId}
          player_${playerId} = new YT.Player('${playerId}', {
            host: 'https://www.youtube-nocookie.com',
            playerVars: ${JSON.stringify(playerVars)},
            events: {
              'onReady': onPlayerReady
            }
          })
      `;

        if (playerVars.autoplay) {
          scriptHtmlOnPlayerReady += `
          autoplayList.push('${playerId}')
        `;
        }
      }); // finish the scriptHtmlOnYouTubeIframeAPIReady string

      scriptHtmlOnYouTubeIframeAPIReady += `
      }
    `; // finish the scriptHtmlOnPlayerReady string

      scriptHtmlOnPlayerReady += `
        const target = event.target
        const id = target.h.id

        if (autoplayList.includes(id)) {
          target.mute()
          target.playVideo()
        }
      }
    `;
      const scriptHtml = scriptHtmlOnYouTubeIframeAPIReady + scriptHtmlOnPlayerReady;
      const bigordrYoutubeScript = document.getElementById(bigordrYoutubeId);

      if (!bigordrYoutubeScript) {
        const script = document.createElement('script');
        script.defer = true;
        script.innerHTML = scriptHtml;
        script.id = bigordrYoutubeId;
        document.head.appendChild(script);
      }
    },

    setBigordrProductsSlider(pageDetails) {
      const bigordrProductsWrapperArray = (0, _jquery.default)('#page-custom-content').find('.bigordr-products-wrapper').toArray();
      const bigordrProductsSliderArray = R.map(wrapper => {
        var _wrapper$dataset, _wrapper$dataset2, _wrapper$dataset3, _wrapper$dataset4;

        const productsForSlider = R.pipe(bigordrProductWrapper => bigordrProductWrapper.getElementsByClassName('bigordr-product'), R.map(productSlider => {
          productSlider.style.display = 'inline-block';
          return productSlider.firstElementChild.dataset.bigordrProduct;
        }), // R.map(itemKey => R.filter(R.pathEq(['_data', 'itemKey'], itemKey))(productsModel)),
        R.flatten)(wrapper);
        productsForSlider.style = wrapper === null || wrapper === void 0 ? void 0 : (_wrapper$dataset = wrapper.dataset) === null || _wrapper$dataset === void 0 ? void 0 : _wrapper$dataset.bigordrProductsSliderType;
        productsForSlider.limitBy = wrapper === null || wrapper === void 0 ? void 0 : (_wrapper$dataset2 = wrapper.dataset) === null || _wrapper$dataset2 === void 0 ? void 0 : _wrapper$dataset2.bigordrProductsSliderLimitBy;
        productsForSlider.rowLimit = wrapper === null || wrapper === void 0 ? void 0 : (_wrapper$dataset3 = wrapper.dataset) === null || _wrapper$dataset3 === void 0 ? void 0 : _wrapper$dataset3.bigordrProductsSliderRowLimit;
        productsForSlider.tags = wrapper === null || wrapper === void 0 ? void 0 : (_wrapper$dataset4 = wrapper.dataset) === null || _wrapper$dataset4 === void 0 ? void 0 : _wrapper$dataset4.bigordrProductsTags; // @TODO: should fetch each slider on its own.
        // currently will require too much to be refactored including limit fetching of products per slider.
        // also need to take into consideration the GET request if sending item keys.
        // solution: each page should store slider id and items.
        // that way the slider should send only the slider id to api and api should return all products in that slider.
        // also need to solve rendering
        // this._getAndFetchProductsForSliderTask.perform(
        //   {
        //     productsToFetch,
        //     wrapper
        //   }
        // )

        return productsForSlider;
      })(bigordrProductsWrapperArray);
      this.set('bigordrProductsSliderArray', bigordrProductsSliderArray);
    },

    setBigordrSlider({
      virtualDom
    }) {
      const bigordrSlider = virtualDom.querySelectorAll('[data-gjs-component="bigordr-slider"]');
      R.forEach(slider => {
        this.applySchedule(slider);
        this.addLinks(slider);
        this.setupTinySlider(slider, virtualDom);
        this.removeUnusedSlider(slider);
      })(bigordrSlider);
    },

    applySchedule(slider) {
      const nowZ = this.date.getNowZ();
      const toRemoveSlideIds = [];
      R.pipe(R.propOr([], 'children'), R.forEach(slide => {
        if (slide.tagName === 'PICTURE') {
          slide = slide.querySelector('img');
        }

        if (!slide) {
          return;
        }

        let {
          gjsModelSchedules = '[]',
          gjsModelStatus
        } = R.pathOr({}, ['dataset'])(slide);
        gjsModelSchedules = _nventor.default.safeParseJSON(gjsModelSchedules);

        if (_nventor.default.isNotNilOrEmpty(gjsModelSchedules)) {
          const currentSchedule = R.find(schedule => {
            const dateStartZ = R.propOr('', 'dateStartZ')(schedule);
            let dateEndZ = R.propOr('', 'dateEndZ')(schedule);

            if (dateEndZ) {
              dateEndZ = this.date.getMoment(dateEndZ).endOf('minute').toISOString();
            }

            if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
              return true;
            }

            if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
              return true;
            }

            if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
              return true;
            }

            return false;
          })(gjsModelSchedules);

          if (_nventor.default.isNotNilOrEmpty(currentSchedule)) {
            const currentScheduleStatus = currentSchedule.status;

            if (currentScheduleStatus === 'inactive') {
              toRemoveSlideIds.push(slide.id);
            }
          } else {
            if (gjsModelStatus === 'inactive') {
              toRemoveSlideIds.push(slide.id);
            }
          }
        }
      }))(slider);
      R.forEach(toRemoveSlideId => {
        const slide = slider.querySelector(`#${toRemoveSlideId}`);

        if (slide.parentElement.tagName === 'PICTURE') {
          slide.parentElement.remove();
        } else {
          slide.remove();
        }
      })(toRemoveSlideIds);
    },

    applyBigordrNativeSliderSchedule(bigordrNativeSliders = []) {
      R.forEach(slider => {
        this.applyScheduleToSlider(slider);
      })(bigordrNativeSliders);
    },

    applyScheduleToSlider(slider) {
      var _parentContainer$data;

      const nowZ = this.date.getNowZ();
      const toRemoveSlides = [];
      const parentContainer = slider.closest('[data-gjs-default-sliders-status]');
      const defaultStatus = (parentContainer === null || parentContainer === void 0 ? void 0 : (_parentContainer$data = parentContainer.dataset) === null || _parentContainer$data === void 0 ? void 0 : _parentContainer$data['gjsDefaultSlidersStatus']) || 'active';
      R.pipe(R.propOr([], 'children'), R.forEach(slide => {
        const gjsModelSchedule = R.pathOr('[]', ['dataset', 'gjsModelSchedule'])(slide);

        const _gjsModelSchedule = _nventor.default.safeParseJSON(gjsModelSchedule);

        if (_nventor.default.isNotNilOrEmpty(_gjsModelSchedule)) {
          const currentSchedule = R.find(schedule => {
            const dateStartZ = R.propOr('', 'dateStartZ')(schedule);
            let dateEndZ = R.propOr('', 'dateEndZ')(schedule);

            if (dateEndZ) {
              dateEndZ = this.date.getMoment(dateEndZ).endOf('minute').toISOString();
            }

            if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
              return true;
            }

            if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
              return true;
            }

            if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
              return true;
            }

            return false;
          })(_gjsModelSchedule); // If default === 'active' => toRemove: has schedule && 'inactive'
          // If default === 'inactive' => toRemove: all except has schedule && 'active'

          if ((currentSchedule === null || currentSchedule === void 0 ? void 0 : currentSchedule.status) === 'inactive') {
            toRemoveSlides.push(slide);
          } else if (defaultStatus === 'inactive') {
            if ((currentSchedule === null || currentSchedule === void 0 ? void 0 : currentSchedule.status) !== 'active') {
              toRemoveSlides.push(slide);
            }
          }
        }
      }))(slider);
      let dotsContainer;
      let sliderContainer;

      if (_nventor.default.isNotNilOrEmpty(toRemoveSlides)) {
        sliderContainer = slider.closest('.lory-slider');
        dotsContainer = sliderContainer.querySelector('ul.lory-dots');
      }

      R.forEach(toRemoveSlide => {
        slider.removeChild(toRemoveSlide);
        dotsContainer.removeChild(dotsContainer.lastChild);
      })(toRemoveSlides);
    },

    setBigordrScratchCards({
      virtualDom,
      scratchCardContainers
    }) {
      if (!scratchCardContainers || (scratchCardContainers === null || scratchCardContainers === void 0 ? void 0 : scratchCardContainers.length) === 0) {
        return;
      }

      const bigordrScratchCardsArray = R.map(scratchCard => {
        const dataset = scratchCard.dataset;

        const getImageUrl = (imageSet = '', imageUrl = '') => {
          imageSet = _nventor.default.safeParseJSON(imageSet);

          if (_nventor.default.isNotNilOrEmpty(imageSet)) {
            var _imageSet, _imageSet2, _imageSet3;

            const clientWidth = document.body.clientWidth;

            if (clientWidth >= this.MEDIA_DESKTOP && (_imageSet = imageSet) !== null && _imageSet !== void 0 && _imageSet.desktopUrl) {
              return imageSet.desktopUrl;
            }

            if (clientWidth < this.MEDIA_DESKTOP && clientWidth >= this.MEDIA_TABLET && (_imageSet2 = imageSet) !== null && _imageSet2 !== void 0 && _imageSet2.tabletUrl) {
              return imageSet.tabletUrl;
            }

            if (clientWidth < this.MEDIA_TABLET && (_imageSet3 = imageSet) !== null && _imageSet3 !== void 0 && _imageSet3.mobileUrl) {
              return imageSet.mobileUrl;
            }
          }

          return imageUrl;
        };

        const coverImageUrl = getImageUrl(dataset === null || dataset === void 0 ? void 0 : dataset.coverImageSet, dataset === null || dataset === void 0 ? void 0 : dataset.coverImageUrl);
        const backgroundImageUrl = getImageUrl(dataset === null || dataset === void 0 ? void 0 : dataset.backgroundImageSet, dataset === null || dataset === void 0 ? void 0 : dataset.backgroundImageUrl);
        return R.mergeRight({ ...scratchCard.dataset
        }, {
          coverImageUrl,
          backgroundImageUrl
        });
      })(scratchCardContainers);
      this.set('bigordrScratchCardsArray', bigordrScratchCardsArray);
    },

    setBigordrForms({
      virtualDom,
      bigordrFormsContainers
    }) {
      if (!bigordrFormsContainers) {
        return;
      }

      if ((bigordrFormsContainers === null || bigordrFormsContainers === void 0 ? void 0 : bigordrFormsContainers.length) === 0) {
        return;
      }

      let initialBigordrFormsArray = R.map(formContainer => {
        var _formContainer$attrib, _formContainer$attrib2;

        return {
          formKey: ((_formContainer$attrib = formContainer.attributes['data-gjs-form-key']) === null || _formContainer$attrib === void 0 ? void 0 : _formContainer$attrib.value) || '',
          formName: ((_formContainer$attrib2 = formContainer.attributes['data-gjs-form-name']) === null || _formContainer$attrib2 === void 0 ? void 0 : _formContainer$attrib2.value) || '',
          id: formContainer.id || '',
          bigordrForm: null
        };
      })(bigordrFormsContainers);
      let updatedBigordrFormsArray = R.map(form => {
        var _this$flowsService;

        const bigordrForm = (_this$flowsService = this.flowsService) === null || _this$flowsService === void 0 ? void 0 : _this$flowsService.getFormFlowsFromAllFormFlows(form.formKey);
        this.set(`bigordrForm_${form.id}`, bigordrForm);
        return R.mergeRight(form, {
          bigordrForm
        });
      })(initialBigordrFormsArray);
      this.set('bigordrFormsArray', updatedBigordrFormsArray);
    },

    addLinks(slider) {
      R.forEach(slide => {
        if (slide.tagName === 'PICTURE') {
          slide = slide.querySelector('img');
        }

        if (!slide) {
          return;
        }

        const {
          gjsModelLink,
          gjsModelButtonlabel
        } = R.pathOr({}, ['dataset'])(slide);
        const hasValidHref = R.pipe(R.replace(/^(https|http):\/\//, ''), R.isEmpty, R.not)(gjsModelLink);

        if (gjsModelLink && hasValidHref) {
          const a = document.createElement('a');
          slide.parentElement.replaceChild(a, slide);
          a.appendChild(slide);
          a.href = gjsModelLink;
        }

        if (gjsModelButtonlabel) {
          const a = document.createElement('a');
          a.text = gjsModelButtonlabel;
          a.classList.add('button', 'slider-button-bottom-right');
          slide.parentElement.appendChild(a);
        }
      })(slider.children || []);
    },

    setupTinySlider(slider, virtualDom) {
      try {
        const {
          id
        } = slider; // Wrap elements with figures

        const elements = Array.from(slider.children);
        R.forEach(element => {
          const figure = document.createElement('figure');
          figure.classList.add('image');
          element.parentElement.replaceChild(figure, element);
          figure.appendChild(element);
        })(elements);

        if (!id) {
          return false;
        }

        const sliderElement = slider;
        const parseSliderDataSet = R.pipe(R.propOr({}, 'dataset'), R.mapObjIndexed((value, key) => {
          return _nventor.default.safeParseJSON(value);
        }), R.toPairs, R.map(R.over(R.lensIndex(0), R.pipe(R.split('gjs'), R.last, R.toLower))), R.fromPairs);
        const options = parseSliderDataSet(sliderElement);
        const defaultTnsOptions = {
          autoplay: false,
          container: sliderElement,
          items: 1,
          mouseDrag: true,
          slideBy: 1,
          swipeAngle: false,
          nav: false,
          navPosition: 'bottom'
        };
        const tnsOptions = R.mergeRight(defaultTnsOptions, options);
        const tinySlider = (0, _tinySlider.tns)(tnsOptions);

        if (tinySlider) {
          const slider = tinySlider.getInfo();
          const container = slider.container;
          const parsedSliderDataSet = parseSliderDataSet(container);
          const sliderArrowColor = R.pipe(R.pathOr('', ['controlsstyle', 'color']), R.replace(/ /gm, ''))(parsedSliderDataSet) || '#000';
          const sliderArrowSize = R.pathOr('small', ['controlssize'])(parsedSliderDataSet);
          const sliderArrowPosition = R.pathOr('insideImage', ['controlsposition'])(parsedSliderDataSet);
          const hasArrows = R.pathOr(true, ['controls'])(parsedSliderDataSet);
          const hasDots = R.path(['nav'])(parsedSliderDataSet);
          const hideControlsBackground = R.pathEq(['hidecontrolsbackground'], true)(parsedSliderDataSet);
          let leftControlsClassNames = 'fas fa-angle-left';
          let rightControlsClassNames = 'fas fa-angle-right';

          if (hasArrows && sliderArrowPosition) {
            const controlsContainerClassNames = [];

            if (sliderArrowPosition === 'insideImage') {
              controlsContainerClassNames.push('tns-controls-container--inside-img');
            }

            if (sliderArrowPosition === 'outsideImage') {
              controlsContainerClassNames.push('tns-controls-container--outside-img');
              controlsContainerClassNames.push(`tns-controls-container--size-${sliderArrowSize}`);
            }
            /** @type {HTMLDivElement} */


            const parentWrapper = slider.container.closest('.tns-ovh');
            parentWrapper.classList.add(...controlsContainerClassNames);
          }

          const nextBtn = slider.nextButton;
          const prevBtn = slider.prevButton;

          if (hasArrows && hideControlsBackground) {
            const hideBackgroundClassName = 'hide-background';
            nextBtn.classList.add(hideBackgroundClassName);
            prevBtn.classList.add(hideBackgroundClassName);
          }

          if (hasArrows && sliderArrowColor) {
            nextBtn.style.color = sliderArrowColor;
            prevBtn.style.color = sliderArrowColor;
          }

          const sliderArrowSizeClassName = `size-${sliderArrowSize}`;

          if (sliderArrowSize) {
            rightControlsClassNames += ` ${sliderArrowSizeClassName}`;
            leftControlsClassNames += ` ${sliderArrowSizeClassName}`;
          }

          const btnClassesNames = ['slider-button', sliderArrowSizeClassName];

          if (hasArrows) {
            this.updateButtonStyle(nextBtn, btnClassesNames, rightControlsClassNames, {
              spanClassName: 'icon--right'
            });
            this.updateButtonStyle(prevBtn, btnClassesNames, leftControlsClassNames, {
              spanClassName: 'icon--left'
            });
          }

          if (hasDots) {
            const sliderDotPosition = parseSliderDataSet(container).navposition;
            const sliderDotsColor = parseSliderDataSet(container).navstyle.color;
            const sliderDotsActiveColor = parseSliderDataSet(container).navstyle.activeColor;
            const sliderDotsStyle = parseSliderDataSet(container).navitemstyle;
            const sliderDotsSize = parseSliderDataSet(container).navitemsize;
            this.updateNavPosition({
              slider,
              position: sliderDotPosition,
              size: sliderDotsSize
            });
            this.updateNavItemsStyle({
              slider,
              style: sliderDotsStyle,
              color: sliderDotsColor,
              activeColor: sliderDotsActiveColor,
              size: sliderDotsSize
            });
            const animationToggleBtns = document.querySelectorAll('[data-action]');
            R.forEach(btn => {
              btn.classList.add('u-hidden');
            })(animationToggleBtns);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    removeUnusedSlider(slider) {
      const sliderChildrenLength = R.length(slider.children) || 0;

      if (sliderChildrenLength === 0) {
        if (slider) {
          slider.remove();
        }
      }
    },

    updateButtonStyle(btn, btnClassesNames, icon, {
      spanClassName = ''
    }) {
      btn.textContent = '';
      btn.classList.add(...btnClassesNames);
      const span = document.createElement('span');
      span.className = `icon icon--center ${spanClassName}`;
      const i = document.createElement('i');
      i.className = icon;
      span.appendChild(i);
      btn.appendChild(span);
    },

    updateNavPosition({
      slider,
      position,
      size
    }) {
      const navContainerClassNames = ['flex-container-row', 'flex-container--justify-center', `tns-nav-container--${size}`];

      if (position === 'insideImage') {
        navContainerClassNames.push('tns-nav-container--inside-img');
      }

      if (position === 'outsideImage') {
        navContainerClassNames.push('tns-nav-container--outside-img');
      }

      const navContainer = slider.navContainer;
      navContainer.classList.add(...navContainerClassNames);
    },

    updateNavItemsStyle({
      slider,
      style,
      color,
      size,
      activeColor
    }) {
      const navItems = slider.navItems;
      const navItemClassNames = ['tns-nav-item', `tns-nav-item--${size}`];

      if (style === 'navItemDot') {
        navItemClassNames.push('tns-nav-item--dot');
      }

      if (style === 'navItemLine') {
        navItemClassNames.push('tns-nav-item--line');
      }

      R.forEachObjIndexed((btn, index) => {
        // btn.style.backgroundColor = color
        const div = document.createElement('div');
        div.setAttribute('data-nav', index);
        const containerWrapperClassNames = ['tns-nav-container--wrapper'];

        if (index === '0') {
          containerWrapperClassNames.push('tns-nav-active');
        }

        div.classList.add(...containerWrapperClassNames);
        btn.textContent = '';
        btn.classList.add(...navItemClassNames);
        div.innerHTML = btn.outerHTML;
        btn.replaceWith(div);
      })(navItems); // Add custom dynamic styles

      const tinySliderOuter = slider.container.closest('.tns-outer');

      if (tinySliderOuter) {
        let styles = [];

        if (color) {
          styles.push(`.tns-nav-item { background-color: ${color}; }`);
        }

        if (activeColor) {
          styles.push(`.tns-nav-item { background-color: ${activeColor}; }`);
        } else {
          styles.push(`.tns-nav-item { filter: brightness(0.5); }`);
        }
        /** @type HTMLDivElement */


        const tinySliderOuterDiv = tinySliderOuter.parentElement;
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
        .tns-nav-active ${styles.join('\n')}
        `;
        tinySliderOuterDiv.prepend(styleElement);
      }
    },

    replaceProductsSliders() {
      const staticWrapperArray = (0, _jquery.default)('#page-custom-content').find('.bigordr-products-wrapper').toArray();
      const dynamicWrapperArray = (0, _jquery.default)('.hidden-components').find('.bigordr-products-wrapper').toArray();
      const hasDynamicWrapperArray = R.pipe(R.isEmpty, R.not)(dynamicWrapperArray);

      if (hasDynamicWrapperArray) {
        R.forEachObjIndexed((value, key) => value.parentElement.replaceChild(dynamicWrapperArray[key], value))(staticWrapperArray);
      }
    },

    replaceScratchCards() {
      const scratchCardsArray = document.querySelector('#page-custom-content').querySelectorAll('[data-gjs-component="bigordr-scratch-card"]');
      const dynamicWrapperArray = document.querySelector('.hidden-components').querySelectorAll('.bigordr-scratch-card__wrapper');
      const hasDynamicWrapperArray = (dynamicWrapperArray === null || dynamicWrapperArray === void 0 ? void 0 : dynamicWrapperArray.length) > 0;

      if (hasDynamicWrapperArray) {
        R.forEachObjIndexed((value, key) => {
          value.parentElement.replaceChild(dynamicWrapperArray[key], value);
        })(scratchCardsArray);
      }
    },

    replaceBigOrdrForm() {
      const formWrapperArray = document.querySelector('#page-custom-content').querySelectorAll('.bigordr-form');
      const dynamicWrapperArray = document.querySelector('.hidden-components').querySelectorAll('.bigordr-form__wrapper');
      const hasDynamicWrapperArray = (dynamicWrapperArray === null || dynamicWrapperArray === void 0 ? void 0 : dynamicWrapperArray.length) > 0;

      if (hasDynamicWrapperArray) {
        R.forEachObjIndexed((value, key) => {
          value.parentElement.replaceChild(dynamicWrapperArray[key], value);
        })(formWrapperArray);
      }
    },

    updateHrefs() {
      const links = document.body.querySelectorAll('[href]');
      const subdomain = R.pathOr('', ['website', '_data', 'subdomain'])(this);
      const domain = R.pathOr('', ['website', '_data', 'domain'])(this);
      const hrefProp = R.pipe(R.propOr('', 'href'), R.trim);
      const findAndReplaceStartsWith = R.curry((toReplace, replaceWith, href) => {
        if (!toReplace) {
          return href;
        }

        toReplace = R.trim(toReplace);
        const startsWith = R.startsWith(toReplace)(href);

        if (startsWith) {
          return R.replace(toReplace, replaceWith)(href);
        }

        return href;
      });
      const regex = RegExp(`${this.configService.config.APP.hostingDomain}`, 'gm'); // UPDATE ANY PASS TO PREVENT RELOAD LINKS

      R.pipe(R.reject(R.pipe(R.propOr('', 'href'), R.anyPass([R.test(regex), R.test(/https:\/\/.+fontawesome.com/gm), R.test(/https:\/\/.+ucarecdn.com/gm), R.test(/https:\/\/cdn.polyfill.io/gm), R.test(/https:\/\/.+facebook.com/gm), R.test(/https:\/\/.+instagram.com/gm), R.test(/https:\/\/.+googleapis.com/gm), R.test(/\.(js|css|html)(\?.*)?$/)]))), R.forEach(link => {
        let newHref = hrefProp(link);

        if (domain) {
          newHref = R.pipe(findAndReplaceStartsWith(`${domain}`, '/'), findAndReplaceStartsWith(`https://${domain}`, '/'), findAndReplaceStartsWith(`http://${domain}`, '/'))(newHref);
          newHref = R.pipe(findAndReplaceStartsWith(`https://${subdomain}.bigordr.com`, '/'), findAndReplaceStartsWith(`http://${subdomain}.bigordr.com`, '/'))(newHref);
        }

        newHref = findAndReplaceStartsWith('//', '/')(newHref);
        link.setAttribute('href', newHref);
      }))(links);
    },

    /**
     * @function applyBodyStyleToDivWrapper
     * @description Applies CSS style to a virtual div based on a template HTML and CSS string.
     * @param {Object} options - An object containing the following properties:
     * @param {string} options.templateHtml - The HTML template used to create the virtual div.
     * @param {string} options.optimizedCss - The CSS style to apply.
     * @return {void} The updated virtual div after applying the CSS style.
     */
    applyBodyStyleToDivWrapper({
      templateHtml,
      optimizedCss
    }) {
      try {
        const htmlElement = document.createElement('html');
        htmlElement.innerHTML = templateHtml;
        const bodyId = htmlElement.querySelector('body').id;

        if (!bodyId) {
          return;
        }

        const divWrapper = document.querySelector('#page-custom-content');
        const parser = new CSSParser();
        const sheet = parser.parse(optimizedCss, false, true);
        const applicableRule = R.pipe(R.pathOr([], ['cssRules']), R.find(R.propEq('mSelectorText', `#${bodyId}`)))(sheet);

        if (!applicableRule) {
          return;
        }

        const styleValue = R.pipe(R.pathOr([], ['declarations']), R.reduce((acc, declaration) => {
          acc += declaration.parsedCssText;
          return acc;
        }, ''))(applicableRule);
        divWrapper.setAttribute('style', styleValue);
      } catch (error) {
        console.error(error);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "didRender", [_object.action], Object.getOwnPropertyDescriptor(_obj, "didRender"), _obj), _applyDecoratedDescriptor(_obj, "replaceDomElementsTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_obj, "replaceDomElementsTask"), _obj), _applyDecoratedDescriptor(_obj, "isAllowToLoadPage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "isAllowToLoadPage"), _obj), _applyDecoratedDescriptor(_obj, "removePlaceHolderImages", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removePlaceHolderImages"), _obj)), _obj));

  _exports.default = _default;
});